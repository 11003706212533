.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.modal-content {
	background: transparent;
	padding: 0;
	border-radius: 16px;
	overflow: hidden;
	position: relative;
	width: auto;
	max-width: 720px;
	max-height: 90vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 20px;
}

.modal-close {
	position: absolute;
	top: 5px;
	right: 5px;
	background: none;
	border: none;
	font-size: 30px;
	cursor: pointer;
	z-index: 1050;
	border-radius: 50%;
	padding: 10px;
	color: grey;
}

.modal-close::after {
	content: '';
	position: absolute;
	top: -91px;
	right: -98px;
	width: 160px;
	height: 160px;
	background: radial-gradient(circle, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0) 70%);
	border-radius: 50%;
}

.modal-content img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	max-width: 100%;
	max-height: 100%;
}

@media screen and (max-width: 720px) {
	.modal-content img {
		width: 100%;
		height: auto;
		max-height: 80vh;
	}
}

/* Pentru imaginea în orientare verticală */
.modal-content img.portrait {
	width: auto;
	height: 100%;
	object-fit: contain;
	max-height: 90vh;
}