/* Navbar container */
.nav {
    padding: 40px 40px 0 40px;
    z-index: 999;
}

.nav-title {
    font-size: 1.8rem;
    color: var(--green-400);
}

.nav-logo img {
    max-height: 50px;
}

.mobile-navbar {
    height: 100dvh;
    width: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.mobile-navbar ul {
    flex-direction: column;
    gap: 50px;
}

.navbar-nav {
    margin-left: 50px;
    column-gap: 25px;
}

.nav-item {
    color: var(--white-100) !important;
    cursor: pointer;
    font-weight: 600;
    opacity: 1;
}

.nav-item:hover {
    color: var(--white-100);
    opacity: 1;
}

.nav-item.active {
    color: var(--green-white-100);
    position: relative;
}

.nav-item.active::before {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    top: 50%;
    left: -30px;
    background: var(--green-400);
    border-radius: 5px;
    transform: rotate(45deg) translateY(-70%);
}

.nav-link {
    color: inherit;
    cursor: pointer;
}

.nav-link:hover {
    color: var(--white-100) !important;
}

.navbar-buttons {
    margin-left: auto;
    column-gap: 20px;
    cursor: pointer;
}

.navbar-responsive-menu {
    display: none !important;
}

.contact-button {
    color: #121212;
    font-weight: 600;
    padding: 10px 15px !important;
    border-radius: 8px !important;
}

/* Media Queries */
@media screen and (max-width: 1100px) {
    .d-none-1100 {
        display: none;
    }

    .navbar-responsive-menu {
        display: block !important;
    }

    .navbar-nav {
        margin-left: 50px;
    }
}

@media screen and (max-width: 960px) {
    .navbar-nav {
        display: none !important;
    }
}

@media screen and (max-width: 560px) {
    .nav {
        padding: 20px 20px 0 20px;
        z-index: 2;
    }
}

/* Dropdown container */
.dropdownMenu {
    position: absolute;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 250px;
    display: flex;
    /* Ascuns de bază */
    flex-direction: column;
    z-index: 9999;
    /* Deasupra altor elemente */
}

/* Dropdown item hover */
.dropdownItem {
    padding: 10px;
    text-align: left;
    text-decoration: none;
    color: #333;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.dropdownItem:hover {
    background-color: #f0f0f0;
}

/* Butonul dropdown */
.dropdownButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.dropdownButton:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

/* Container pentru butonul și dropdown-ul */
.nav-item {
    position: relative;
    /* Asigură-te că dropdown-ul este poziționat corect */
}