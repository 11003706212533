.youtube-wrapper {
	width: 100%;
	display: flex;
	aspect-ratio: 3/1;
	position: relative;
	margin-top: 50px;
}

.youtube-wrapper:after {
	content: "";
	position: absolute;
	z-index: 99;
	bottom: 0;
	left: 0;
	pointer-events: none;
	background-image: linear-gradient(to bottom, rgba(9, 9, 10, 0), rgba(9, 9, 10, 1) 90%);
	width: 100%;
	height: 10rem;
}

.youtube-wrapper:before {
	content: "";
	position: absolute;
	z-index: 99;
	top: 0;
	left: 0;
	pointer-events: none;
	background-image: linear-gradient(to top, rgba(9, 9, 10, 0), rgba(9, 9, 10, 1) 90%);
	width: 100%;
	height: 10rem;
}

.youtube-video {
	width: 100%;
	object-fit: cover;
	height: 100%;
	opacity: 0.3;
}

.youtube-video iframe {
	height: 100%;
}

.youtube-title {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-family: "Lora", serif !important;
	font-optical-sizing: auto !important;
	font-weight: 700 !important;
	font-style: normal !important;
	color: var(--cinematic-yellow);
	z-index: 99999;
	font-size: 6rem;
	cursor: pointer;
}

.youtube-title:hover {
	color: var(--white-100);
}

@media screen and (max-width: 720px) {
	.youtube-wrapper {
		aspect-ratio: 16/9;
	}

	.youtube-title {
		font-size: 3rem;
	}

	.youtube-wrapper:before {
		height: 4rem;
	}

	.youtube-wrapper:after {
		height: 4rem;
	}
}