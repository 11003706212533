.gallery-setting {
    width: 100%;
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
}

.gallery-setting h1 {
    color: var(--cinematic-yellow);
    font-family: "Archivo Black", sans-serif !important;
    font-weight: 400;
    font-size: 1.5rem;
    font-weight: bold;
}

.gallery-titles {
    display: flex;
    align-items: center;
    gap: 20px;
}

.gallery-titles small {
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    opacity: 0.5;
}

.christmas.btn-mix-noborder {
    max-width: 250px;
    margin: 20px auto;
    color: #121212;
}

.btn-mix-noborder {
    outline: 0;
    border-radius: 10px;
    font-size: 0.8em;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    overflow: hidden;
    position: relative;
    padding: 15px 20px;
    text-transform: uppercase;
    -webkit-tap-highlight-color: transparent;
    transition: all 500ms ease-in-out;
    display: flex;
    white-space: nowrap;
}

.btn-mix-noborder::after,
.btn-mix-noborder::before {
    content: "";
    position: absolute;
}

.btn-mix-noborder:hover,
.btn-mix-noborder:focus {
    background: rgba(255, 255, 255, 0.8);
}

.btn-mix-noborder::after {
    background: #222;
    border-radius: 10px;
    content: attr(data-text);
    color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 700;
    white-space: nowrap;
}

.btn-mix-noborder::before {
    background: transparent;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    box-shadow: 0 1em 2em 3em rgba(118, 38, 209, 0.5), 4em 0em 2em 2em rgba(96, 35, 208, 0.6), -4em -2em 2em 2em rgba(79, 22, 184, 0.5), -4em 2em 2em 4em rgba(0, 0, 150, 0.5), 0 -3em 2em 3em rgba(24, 118, 194, 0.5), 2em -1em 2em 2em rgba(50, 10, 200, 0.5);
    width: 0;
    height: 0;
    -webkit-animation: mix 9s linear infinite;
    animation: mix 9s linear infinite;
}

.christmas.btn-mix-noborder::before {
    -webkit-animation: christmas-mix 9s linear infinite;
    animation: christmas-mix 9s linear infinite;
    box-shadow: 0 1em 2em 3em rgba(52, 209, 38, 0.5), 4em 0em 2em 2em rgba(64, 208, 35, 0.6), -4em -2em 2em 2em rgba(184, 52, 22, 0.5), -4em 2em 2em 4em rgba(150, 0, 0, 0.5), 0 -3em 2em 3em rgba(24, 194, 61, 0.5), 2em -1em 2em 2em rgba(200, 45, 10, 0.5);
}


@keyframes christmas-mix {
    30% {
        box-shadow: 0 -1em 2em 3em rgba(34, 188, 101, 0.5), 4em -1em 2em 3em rgba(46, 208, 76, 0.8), -4em 2em 3em 2em rgba(62, 185, 21, 0.5), -4em -2em 2em 2em rgba(202, 40, 40, 0.6), 0 3em 2em 2em rgba(184, 49, 22, 0.5), 2em 1em 2em 2em rgba(215, 4, 4, 0.67);
    }

    60% {
        box-shadow: -4em 2em 2em 3em rgba(202, 99, 44, 0.6),
            -2em 1em 2em 3em rgba(250, 50, 50, 0.6), 2em 1em 2em 3em rgba(0, 200, 83, 0.5), 3em 1em 2em 3em rgba(85, 150, 0, 0.6), 3em -2em 3em 3em rgba(103, 200, 0, 0.5), 0 -3em 2em 3em rgba(182, 39, 23, 0.5);
    }
}

@-webkit-keyframes mix {
    30% {
        box-shadow: 0 -1em 2em 3em rgba(81, 42, 208, 0.5), 4em -1em 2em 3em rgba(115, 45, 207, 0.8), -4em 2em 3em 2em rgba(0, 200, 190, 0.5), -4em -2em 2em 2em rgba(75, 29, 201, 0.6), 0 3em 2em 2em rgba(8, 82, 185, 0.5), 2em 1em 2em 2em rgba(50, 10, 250, 0.5);
    }

    60% {
        box-shadow: 2em 1em 2em 3em rgba(55, 0, 200, 0.5), 3em 1em 2em 3em rgba(0, 67, 150, 0.6), 3em -2em 3em 3em rgba(0, 163, 200, 0.5), -4em 2em 2em 3em rgba(146, 36, 202, 0.6), 0 -3em 2em 3em rgba(24, 113, 191, 0.5), -2em 1em 2em 3em rgba(50, 210, 250, 0.6);
    }
}

@keyframes mix {
    30% {
        box-shadow: 0 -1em 2em 3em rgba(103, 34, 188, 0.5), 4em -1em 2em 3em rgba(76, 46, 208, 0.8), -4em 2em 3em 2em rgba(21, 136, 185, 0.5), -4em -2em 2em 2em rgba(89, 40, 202, 0.6), 0 3em 2em 2em rgba(22, 114, 184, 0.5), 2em 1em 2em 2em rgba(50, 10, 250, 0.5);
    }

    60% {
        box-shadow: 2em 1em 2em 3em rgba(55, 0, 200, 0.5), 3em 1em 2em 3em rgba(0, 97, 150, 0.6), 3em -2em 3em 3em rgba(0, 173, 200, 0.5), -4em 2em 2em 3em rgba(60, 44, 202, 0.6), 0 -3em 2em 3em rgba(23, 126, 182, 0.5), -2em 1em 2em 3em rgba(50, 210, 250, 0.6);
    }
}

.btn-mix-noborder::after {
    background: transparent !important;
    color: #fff !important;
}

.btn-mix-noRadius {
    border-radius: 0 !important;
}

.btn-mix-noRadius::after,
.btn-mix-noRadius::before {
    border-radius: 0 !important;
}

.tools {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 700px) {
    .gallery-setting .break {
        flex-basis: 100%;
        height: 0;
    }

    .gallery-setting {
        flex-direction: column;
        gap: 10px;
    }
}

.contact-button {
    color: #121212;
    font-weight: 600;
    padding: 10px 15px !important;
    border-radius: 8px !important;
}

.contact-area {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 0px;
}

/* AboutMe.module.css sau in App.module.css */

.about-me-section {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60vh;
    padding: 20px;
    background-color: #000;
    /* Fundal negru */
    color: #fff;
    /* Text alb */
}

.about-me-content {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.profile-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 20px;
    border: 2px solid #fff;
    object-fit: cover;
    /* Bordură albă pentru contrast */
}

.bio-text h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #fff;
    /* Titlu alb */
}

.bio-text p {
    font-size: 16px;
    line-height: 1.5;
    color: #ccc;
    /* Text gri deschis pentru a fi mai */
}

.appointment-area {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}


@media screen and (max-width: 780px) {
    .appointment-area {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
}





/* PACHETE STUDIO PACKS */

.pack-1 {
    border-radius: 15px;
    padding: 25px;
    text-align: center;
    color: #f0f0f0;
    background: linear-gradient(115deg, #8a2387, #f27121, #09090A);
    background-size: 200% 200%;
    animation: subtleGlow 10s ease-in-out infinite alternate;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    max-width: 400px;
    margin-bottom: 30px;
    width: 100%;

}

@keyframes subtleGlow {
    0% {
        background-position: 0% 50%;
    }

    100% {
        background-position: 100% 100%;
    }
}

.pack-1 h1 {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 600;
}

.pack-1 p {
    font-size: 14px;
    opacity: 0.8;
}

.pack-1 h2 {
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    /* Un auriu subtil pentru evidențiere */
    margin-top: 15px;
}






.pack-2 {
    border-radius: 15px;
    padding: 25px;
    text-align: center;
    color: #f0f0f0;
    background: linear-gradient(115deg, #093028, #237a57, #09090A);
    background-size: 200% 200%;
    animation: subtleGlow 9s ease-in-out infinite alternate;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    max-width: 400px;
    width: 100%;
    margin-bottom: 30px;
}

.pack-2 h1 {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 600;
}

.pack-2 p {
    font-size: 14px;
    opacity: 0.8;
}

.pack-2 h2 {
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    /* Un auriu subtil pentru evidențiere */
    margin-top: 15px;
}





.pack-3 {
    border-radius: 15px;
    padding: 25px;
    text-align: center;
    color: #f0f0f0;
    background: linear-gradient(115deg, #141e30, #243b55, #09090A);
    background-size: 200% 200%;
    animation: subtleGlow 8s ease-in-out infinite alternate;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    max-width: 400px;
    width: 100%;
}

.pack-3 h1 {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 600;
}

.pack-3 p {
    font-size: 14px;
    opacity: 0.8;
}

.pack-3 h2 {
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    /* Un auriu subtil pentru evidențiere */
    margin-top: 15px;
}