.brick-layout {
    position: absolute;
    width: 60%;
    height: 100%;
    top: 0;
    right: -40px;
    display: flex;
    column-gap: 15px;
    overflow: hidden;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
}


.brick-column {
    width: 100%;
    height: 100%;
}

.brick-column:first-child {
    transform: translateY(-220px);

    animation-duration: 9s;
    animation-name: top-animation-1;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
}

.brick-column:nth-child(2) {
    transform: translateY(-130px);

    animation-duration: 9s;
    animation-name: top-animation-2;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
}

.brick-column:nth-child(2) img:nth-child(2) {
    height: 400px;
}

.brick-column:nth-child(3) {
    transform: translateY(-240px);

    animation-duration: 9s;
    animation-name: top-animation-3;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
}

@keyframes top-animation-1 {
    from {
        transform: translateY(50px);
    }

    to {
        transform: translateY(-750px);
    }
}

@keyframes top-animation-2 {
    from {
        transform: translateY(-720px);
    }

    to {
        transform: translateY(10px);
    }
}

@keyframes top-animation-3 {
    from {
        transform: translateY(100px);
    }

    to {
        transform: translateY(-650px);
    }
}


.brick-column img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    margin-bottom: 15px;
    border-radius: 10px;
}

@media screen and (max-width: 1100px) {
    .brick-layout {
        width: 70%;
    }
}

@media screen and (max-width: 960px) {
    .brick-layout {
        width: 80%;
    }
}

@media screen and (max-width: 840px) {
    .brick-layout {
        right: 50%;
        transform: translate(50%, -105%);
        height: 50%;
        top: 100%;
        border-radius: 10px;
    }

    .brick-layout::before {
        opacity: .6;
    }
}

@media screen and (max-width: 700px) {
    .brick-layout {
        border-radius: 0;
        width: 100%;
        margin-top: 30px;
    }
}