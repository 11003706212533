.header {
    position: relative;
    height: 720px;
    overflow: hidden;
}

.header.short {
    height: 520px;
}

.header.menuOpen {
    height: 100dvh !important;
}

.blur-circle-shape {
    position: absolute;
    top: -95px;
    left: -95px;
    border-radius: 100%;
    width: 250px;
    height: 250px;
    background: var(--green-400);
    z-index: 0;
    filter: blur(250px);
}

.blur-circle-shape.christmas {
    background: #2F7336;
    background-image: linear-gradient(60deg, #00FF40, #E81828);
    top: -155px;
    left: -95px;
    width: 600px;
    height: 600px;
    filter: blur(150px);
    animation: spin 5s linear infinite;
}

.blur-circle-shape.events {
    background: #7F7FD5;
    background-image: linear-gradient(45deg, #A770EF, #B2FEFA, #FBD786, #FDB99B);
    top: -155px;
    left: -95px;
    width: 450px;
    height: 450px;
    filter: blur(150px);
    animation: spin 5s linear infinite;
}

.blur-circle-shape.studio {
    background: #7F7FD5;
    background-image: linear-gradient(45deg, #e96443, #904e95);
    top: -155px;
    left: -95px;
    width: 450px;
    height: 450px;
    filter: blur(150px);
    animation: spin 5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.headings-header {
    height: 100%;
    margin-left: 40px;
    z-index: 2;
    max-width: 40%;
}

.heading-header-title {
    position: relative;
    font-size: 1rem;
    color: var(--white-100);
    margin-left: 70px;
    font-style: italic;

}

.heading-header-title::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -70px;
    transform: translateY(-50%);
    width: 60px;
    height: 2px;
    background-color: var(--white-100);
}

.heading-header-title::after {
    content: '';
    position: absolute;
    top: 50%;
    left: -28px;
    transform: translateY(-50%) rotate(45deg);
    width: 10px;
    height: 10px;
    background-color: var(--white-100);
    box-shadow: 0 0 1px 3px var(--dark-900);
    border-radius: 4px;
}

.heading-header-second-title {
    color: var(--cinematic-yellow);
    font-size: 2rem;
    margin-top: 30px;
    font-weight: 400;
    line-height: 50px;
    font-weight: 200 !important;
    line-height: 2rem;
    font-family: "Archivo Black", sans-serif !important;
    font-weight: 400;
    font-style: normal;
}

.heading-header-second-title-christmas {
    color: var(--white-100);
    font-size: 1.2rem;
    margin-top: 30px;
    font-weight: 200 !important;
    line-height: 2rem;
    font-family: "Archivo Black", sans-serif !important;
    font-weight: 400;
    font-style: normal;
    text-align: center;
}

.heading-header-second-title-events {
    color: var(--dark-900);
    font-size: 1.2rem;
    margin-top: 30px;
    font-weight: 200 !important;
    line-height: 2rem;
    font-family: "Archivo Black", sans-serif !important;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    margin-bottom: 20px;
}

.heading-header-second-title-studio {
    color: var(--white-100);
    font-size: 1.2rem;
    margin-top: 30px;
    font-weight: 200 !important;
    line-height: 2rem;
    font-family: "Archivo Black", sans-serif !important;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    margin-bottom: 20px;
}

.studio strong {
    animation: glowTitle 2s ease-in-out infinite alternate;
}

@keyframes glowTitle {
    from {
        text-shadow: 0 0 1px #fff, 0 0 15px #fcbe11;
    }

    to {
        text-shadow: 0 0 3px #fff, 0 0 30px #fcbe11;
    }
}

.christmas .heading-header-second-title {
    color: #00FF40;
}

.heading-header-second-title span {
    color: var(--white-100);
    font-weight: 200 !important;
    line-height: 2rem;
    font-family: "Archivo Black", sans-serif !important;
}

h3.heading-header-signature {
    font-family: "Sassy Frass", cursive !important;
    font-weight: 400;
    font-style: normal;
    font-size: 4rem;
    color: var(--white-100);
    margin-top: -20px;
}

.search-bar {
    position: relative;
    width: 600px;
    height: 60px;
    margin-top: 30px;
    padding: 0 8px 0 20px;
    column-gap: 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.search-input {
    width: 100%;
    height: 100%;
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 1.1rem;
    color: var(--white-100);
}

.search-input::placeholder {
    color: var(--dark-600);
}

.search-btn {
    position: relative;
    width: 50px;
    height: 40px;
    border-radius: 0;
    background-color: transparent;
    cursor: pointer;
}

.search-btn::before {
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -1;
}

.scroll-down {
    color: var(--white-100);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 50px;
    font-weight: 500;
    opacity: 0.9;
    cursor: pointer;
}

.scroll-down:hover {
    opacity: 1;
}

.scroll-down img {
    height: 12px;
    margin-right: 10px;
}

@media screen and (max-width: 840px) {
    .headings-header {
        align-items: center;
        margin-left: 0;
        height: 30%;
        padding: 50px;
        text-align: center;
        z-index: 0;
    }

    .headings-header {
        max-width: 100%;
    }

    .heading-header-title {
        display: none;
    }
}

@media screen and (max-width: 700px) {
    .heading-header-title {
        margin-left: -50px;
    }

    .heading-header-second-title {
        font-size: 2rem;
    }

    .search-bar {
        width: 500px;
    }

    .search-btn {
        width: 40px;
        height: 30px;
    }

    .scroll-down {
        margin-top: 15px;
    }
}

.socials {
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    gap: 25px;
}

.socials img {
    height: 26px;
    filter: grayscale();
    cursor: pointer;
}

.socials img:hover {
    filter: none;
}