@import "./css-reset.css";

@font-face {
    font-family: Studiio;
    font-weight: bold;
    src: url("../public/fonts/STUDIIO-Bold\ V2.otf") format("opentype");
}

:root {
    --white-100: #FFFFFF;
    --white-bg: #F4F3F2;
    --dark-900: #09090A;
    --dark-600: #808090;
    --green-400: #c81b1b;
    --green-white-100: #efc0c0;
    --cinematic-yellow: #fcbe11;
}

strong {
    font-weight: 600;
}

.containerBody {
    background: var(--dark-900);
}

.containerBody.events {
    background: var(--white-bg);
}

/* my own classes */
.flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.justify-content-center {
    justify-content: center;
}

.align-items-center {
    align-items: center;
}

.justify-content-between {
    justify-content: space-between;
}

body .carousel .carousel-dots li {
    vertical-align: middle;
}

body .carousel .carousel-dots {
    bottom: -40px;
    vertical-align: middle;
}

body .carousel .carousel-dots button {
    font-size: 1.7rem;
    padding: 2px;
    color: #666;
}

body .carousel .carousel-dot.selected {
    color: #00A0EF;
    font-size: 2rem !important;
}

/* 
body .carousel.more .carousel-dots li:first-child button {
    font-size: 1rem;
}

body .carousel.more .carousel-dots li:last-child button {
    font-size: 1rem;
}

body .carousel.more .carousel-dots li:nth-last-child(2) button {
    font-size: 1.5rem;
}

body .carousel.more .carousel-dots li:nth-child(2) button {
    font-size: 1.5rem;
} */

/* Container pentru card */
/* Container pentru card */
/* Container pentru card */
.card-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    width: 100%;
    max-width: 420px;
    padding: 20px;
    border: 1px solid #dddddd;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.form-group label {
    font-size: 14px;
}

/* Stilizarea selectului */
.custom-select {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    padding: 10px 15px;
    margin-bottom: 20px;
    border: none;
    border-bottom: 0px solid #18191a;
    background: none;
    cursor: pointer;
    outline: none;
    appearance: none;
    width: 100%;
    text-align: center;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 24 24' fill='none' stroke='%2318191a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'%3E%3C/path%3E%3C/svg%3E") no-repeat right 15px center;
    background-size: 16px;
}

/* Input-uri stilizate */
.custom-input {
    width: 100%;
    padding: 10px 15px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-bottom: 15px;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
}

.custom-input:focus,
.custom-select:focus {
    border-color: #007bff;
    outline: none;
}

/* Checkbox container */
.checkbox-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkbox-container label {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 700;
    margin-bottom: 20px;
}

.custom-checkbox {
    appearance: none;
    width: 24px;
    height: 24px;
    border: 2px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.custom-checkbox:checked {
    border-color: #007bff;
    background-color: #007bff;
}

.custom-checkbox:checked::before {
    content: "✔";
    font-size: 14px;
    color: #fff;
    position: absolute;
}

/* Butonul de la bază */
.custom-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 15px;
}

.custom-button span {
    margin-left: 5px;
    font-size: 1.2rem;
}

.custom-button:hover {
    background-color: #0056b3;
}

.contactForm .custom-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}




.price-estimate {
    width: 100% !important;
    max-width: 420px;
    align-self: flex-start;
}

.contact-form {
    width: 100% !important;
    max-width: 420px;
}

@media screen and (max-width: 780px) {
    .appointment-area {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    .price-estimate {
        width: 100% !important;
        max-width: 420px;
        align-self: center;
    }
}













.image_outer_container {
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 50%;
    position: relative;
    width: 55px;
    height: 55px;
    margin: 15px;
    cursor: pointer;
}

.image_inner_container {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    padding: 4%;
    background: #833ab4;
    background: -webkit-linear-gradient(225deg, #BA0FFD, #FE2108, #FEFF1B);
    background: linear-gradient(225deg, #BA0FFD, #FE2108, #FEFF1B);
    transition: background 0.6s ease-in-out;
}

.image_inner_container:hover {
    background-position: -55px;
}

.image_inner_container img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: 4px solid var(--dark-900);
    background: #000;
}

.nav-item {
    color: var(--white-100) !important;
    cursor: pointer;
    font-weight: 600;
    opacity: 1;
}

.nav-item strong {
    font-family: Studiio !important;
    animation: none !important;
}


.nav-item:hover {
    color: var(--dark-600);
    opacity: 1;
}

.nav-item.active {
    color: var(--green-white-100);
    position: relative;
}

.nav-item.active::before {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    top: 50%;
    left: -30px;
    background: var(--green-400);
    border-radius: 5px;
    transform: rotate(45deg) translateY(-70%);
}

.nav-link {
    color: inherit;
    cursor: pointer;
}

.nav-link:hover {
    color: var(--white-100) !important;
}

.containerBody.events .map-layout-control small {
    color: var(--dark-900) !important;
}

.containerBody.events {
    color: var(--dark-900) !important;
}

.containerBody .mobile-navbar-control ul li .nav-link {
    color: var(--white-100) !important;
}


.containerBody.events .nav-item {
    color: var(--dark-900) !important;
}

.containerBody.events .nav-link {
    color: var(--dark-900) !important;
    opacity: 1;
}

.containerBody.events .nav-link:hover {
    color: var(--dark-900) !important;
    opacity: 1;
}


.containerBody.events .nav-link {
    color: var(--dark-900) !important;
    opacity: 1;
    font-weight: 600;
}

.containerBody.events .nav-link:hover {
    color: var(--dark-900) !important;
    opacity: 1;
}

.containerBody.events .header-title-control {
    color: var(--dark-900) !important;
}

.containerBody.events .header-second-title-control {
    color: var(--dark-900) !important;
}

.containerBody.events .header-second-title-control span {
    color: var(--cinematic-yellow) !important;
}

.containerBody.events .header-signature-control {
    color: var(--dark-900) !important;
}

.containerBody.events .scroll-down-control {
    color: var(--dark-900) !important;
}

.containerBody.events .dropdown-control button {
    color: var(--dark-900) !important;
}

.containerBody.events .dropdown-control button svg {
    fill: var(--dark-900) !important;
}

.containerBodya.studio h3 {
    color: #fff !important;
}

.contactForm {
    max-width: 600px;
    /* Formular mai lat */
    border: 1px solid #dddddd;
    /* Bordură subtilă */
    border-radius: 15px;
    padding: 30px;
    box-sizing: border-box;
}

.contactForm.white {
    border: 1px solid #222;
}

.contactForm.white label {
    color: var(--white-100) !important;
}

.contactForm form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contactForm label {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
}

.contactForm textarea {
    width: 100%;
    padding: 15px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    font-size: 16px;
    color: #333333;
    box-sizing: border-box;
}

.contactForm textarea {
    resize: none;
    height: 120px;
}

.contactForm button {
    background-color: #007bff;
    color: white;
    padding: 15px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.contactForm button:hover {
    background-color: #0056b3;
}

.contactForm .error {
    color: #d8000c;
    background-color: #ffbaba;
    padding: 10px;
    border-radius: 4px;
    font-size: 14px;
}

.contactForm p {
    color: #007bff;
    font-weight: bold;
    font-size: 14px;
    margin: 0;
    text-align: center;
}

@keyframes glowing {
    0% {
        box-shadow: 0 0 8px rgba(0, 123, 255, 0.2), 0 0 15px rgba(0, 123, 255, 0.2);
    }

    50% {
        box-shadow: 0 0 15px rgba(0, 123, 255, 0.3), 0 0 25px rgba(0, 123, 255, 0.3);
    }

    100% {
        box-shadow: 0 0 8px rgba(0, 123, 255, 0.2), 0 0 15px rgba(0, 123, 255, 0.2);
    }
}

.contactForm.glow {
    animation: glowing 2s ease-in-out infinite;
    border: 1px solid rgba(0, 123, 255, 0.2);
    /* Bordură mai subtilă */
}

.contactForm.modal {
    width: 720px;
    max-width: 100%;
    background-color: var(--white-100);
}










/* Containerul mare */
.user-selections-container {
    position: relative;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    /* Ascunde orice depășire din containerul mare */
}

/* Containerul de scroll care va conține atât masca cât și elementele scrollabile */
.user-selections-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    overflow-x: auto;
    padding-bottom: 7px;
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0) 95%);
    /* Masca pentru partea dreaptă */
    -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0) 95%);
    /* Safari */
    padding-right: 40px;
    /* Lasă loc pentru săgeată */
}

/* Lista de selecții */
.user-selections {
    display: flex;
    gap: 10px;
    flex-wrap: nowrap;
    white-space: nowrap;
}

/* Elementul de selecție */
.selection-item {
    display: inline-block;
    padding: 8px 0px;
    background-color: #f4f4f4;
    border-radius: 5px;
    font-size: 14px;
    text-align: left;
    text-transform: capitalize;
}

.selection-item:last-child {
    margin-right: 20px;
}

.selection-item strong {
    font-weight: bold;
}

/* Săgeata care va rămâne deasupra */
.arrow {
    position: absolute;
    right: 10px;
    font-size: 20px;
    color: var(--dark-900);
    pointer-events: none;
    z-index: 3;
    /* Asigură că săgeata rămâne deasupra altor elemente */
    top: 50%;
    transform: translateY(calc(-50% - 3px));
}








/* Responsive styling */
@media (max-width: 480px) {
    .contactForm {
        padding: 20px;
    }
}


.appointment-control-christmas {
    display: block !important;
    width: 80%;
    margin: 0 auto;
}

.appointment-studio-control {
    flex-direction: column !important;
}

.appointment-studio-control .contactForm {
    width: 100%;
    margin-top: 20px;
}