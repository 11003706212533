.container {
    padding: 25px 0;
    margin: 0 auto;
    width: 100%;
}

.gallery-wrap {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 70vh;
}

.item {
    flex: 1;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: none;
    transition: flex 0.8s ease;

    &:hover {
        flex: 4;
    }
}

@media screen and (max-width: 720px) {
    .item {
        &:hover {
            flex: 6;
        }
    }
}

.item-1 {
    background-image: url('../../../public/img/christmas/hiq/christmas1.jpeg');
}

.item-1.events {
    background-image: url('../../../public/img/events/botez1.jpeg');
}

.item-1.studio {
    background-image: url('../../../public/img/studio/studio1.jpeg');
}

.item-2 {
    background-image: url('../../../public/img/christmas/hiq/christmas2.jpeg');
}

.item-2.events {
    background-image: url('../../../public/img/events/maj-bianca10.jpeg');
}

.item-2.studio {
    background-image: url('../../../public/img/studio/studio8.jpeg');
}

.item-3 {
    background-image: url('../../../public/img/christmas/christmas16.jpeg');
}

.item-3.events {
    background-image: url('../../../public/img/events/wed4.jpg');
}

.item-3.studio {
    background-image: url('../../../public/img/studio/studio3.jpeg');
}

.item-4 {
    background-image: url('../../../public/img/christmas/christmas13.jpeg');
}

.item-4.events {
    background-image: url('../../../public/img/events/wed1.jpg');
}

.item-4.studio {
    background-image: url('../../../public/img/studio/studio4.jpeg');
}

.item-5 {
    background-image: url('../../../public/img/christmas/hiq/christmas5.jpeg');
}

.item-5.events {
    background-image: url('../../../public/img/events/maj-bianca6.jpeg');
}

.item-5.studio {
    background-image: url('../../../public/img/studio/studio6.jpeg');
}

.item-6 {
    background-image: url('../../../public/img/christmas/santa11.jpeg');
}

.item-6.events {
    background-image: url('../../../public/img/events/events5.jpeg');
}

.item-6.studio {
    background-image: url('../../../public/img/studio/valentines2.jpeg');
}

.social {
    position: absolute;
    right: 35px;
    bottom: 0;

    img {
        display: block;
        width: 32px;
    }
}