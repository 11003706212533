.instagram-tag {
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
}

.instagram-tag--details {
	display: flex;
	flex-direction: column;
	color: var(--white-100);
	justify-content: space-around;
}

.instagram-tag--name {
	font-weight: 800;
	font-size: 0.9rem;
	margin-bottom: 5px;
}

.instagram-tag--location {
	font-size: 0.8rem;
}

.instagram-icon {
	height: 30px;
	margin: 12px;
	margin-left: auto;
}

.instagram-icon img {
	height: 100%;
}