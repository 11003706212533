.map-layout {
	display: flex;
	background-image: url('../../../public/img/MAP1.png');
	aspect-ratio: 3/1;
	background-size: cover;
	background-position: center;
	-webkit-transition: background-image 1s ease-in-out;
	transition: background-image 1s ease-in-out;
	flex-direction: column;
	justify-content: center;
}

.map-layout small {
	font-weight: 700;
	opacity: 0.7;
	font-size: 12px;
}

.map-layout:hover {
	background-image: url('../../../public/img/MAP2.png');
}

.map-controls {
	align-self: flex-start;
	color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 15px;
	margin-left: 25%;
}

@media screen and (max-width: 700px) {
	.map-layout {
		aspect-ratio: 16/12;
		background-size: 130%;
		background-position: right;
	}

	.map-controls {
		align-self: center;
		margin-left: 0;
	}

	.btn-mix-noborder {
		background: rgba(255, 255, 255, 0.8);
	}

	.map-layout.onScreen {
		background-image: url('../../../public/img/MAP2.png');
	}

}



.btn-mix-noborder {
	outline: 0;
	border-radius: 50px;
	font-size: 0.6em;
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	letter-spacing: 1px;

	overflow: hidden;
	position: relative;
	padding: 16px 40px;
	text-transform: uppercase;
	-webkit-tap-highlight-color: transparent;
	transition: all 500ms ease-in-out;
	display: flex;
	border-radius: 25px;
}

.btn-mix-noborder::after,
.btn-mix-noborder::before {
	content: "";
	position: absolute;
}

.btn-mix-noborder:hover,
.btn-mix-noborder:focus {
	background: rgba(255, 255, 255, 0.8);
}

.btn-mix-noborder::after {
	background: #222;
	border-radius: 120px;
	content: attr(data-text);
	color: #fff;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-weight: 700;
	white-space: nowrap;
}

.btn-mix-noborder::before {
	background: transparent;
	border-radius: 100%;
	top: 50%;
	left: 50%;
	box-shadow: 0 1em 2em 3em rgba(118, 38, 209, 0.5), 4em 0em 2em 2em rgba(96, 35, 208, 0.6), -4em -2em 2em 2em rgba(79, 22, 184, 0.5), -4em 2em 2em 4em rgba(0, 0, 150, 0.5), 0 -3em 2em 3em rgba(24, 118, 194, 0.5), 2em -1em 2em 2em rgba(50, 10, 200, 0.5);
	width: 0;
	height: 0;
	-webkit-animation: mix 5s linear infinite;
	animation: mix 5s linear infinite;
}

@-webkit-keyframes mix {
	30% {
		box-shadow: 0 -1em 2em 3em rgba(81, 42, 208, 0.5), 4em -1em 2em 3em rgba(115, 45, 207, 0.8), -4em 2em 3em 2em rgba(0, 200, 190, 0.5), -4em -2em 2em 2em rgba(75, 29, 201, 0.6), 0 3em 2em 2em rgba(8, 82, 185, 0.5), 2em 1em 2em 2em rgba(50, 10, 250, 0.5);
	}

	60% {
		box-shadow: 2em 1em 2em 3em rgba(55, 0, 200, 0.5), 3em 1em 2em 3em rgba(0, 67, 150, 0.6), 3em -2em 3em 3em rgba(0, 163, 200, 0.5), -4em 2em 2em 3em rgba(146, 36, 202, 0.6), 0 -3em 2em 3em rgba(24, 113, 191, 0.5), -2em 1em 2em 3em rgba(50, 210, 250, 0.6);
	}
}

@keyframes mix {
	30% {
		box-shadow: 0 -1em 2em 3em rgba(103, 34, 188, 0.5), 4em -1em 2em 3em rgba(76, 46, 208, 0.8), -4em 2em 3em 2em rgba(21, 136, 185, 0.5), -4em -2em 2em 2em rgba(89, 40, 202, 0.6), 0 3em 2em 2em rgba(22, 114, 184, 0.5), 2em 1em 2em 2em rgba(50, 10, 250, 0.5);
	}

	60% {
		box-shadow: 2em 1em 2em 3em rgba(55, 0, 200, 0.5), 3em 1em 2em 3em rgba(0, 97, 150, 0.6), 3em -2em 3em 3em rgba(0, 173, 200, 0.5), -4em 2em 2em 3em rgba(60, 44, 202, 0.6), 0 -3em 2em 3em rgba(23, 126, 182, 0.5), -2em 1em 2em 3em rgba(50, 210, 250, 0.6);
	}
}

.btn-mix-noborder::after {
	background: transparent !important;
	color: #fff !important;
}

.btn-mix-noRadius {
	border-radius: 0 !important;
}

.btn-mix-noRadius::after,
.btn-mix-noRadius::before {
	border-radius: 0 !important;
}